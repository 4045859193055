<template>
  <w3pp-button
    class='block trigger'
    background="transparent"
    color="#b8bfce"
    @click="open = true"
  >
      <div class="icon"><icon
          icon="chatIcon"
          height="18"
      /></div>
      <div class="text">
        Give feedback
        <div class="helper">Help us improve Spenda</div>
      </div>
  </w3pp-button>

  <w3pp-modal
    :open="open"
    floatingClose
    @backgroundClick="open = false"
    @close="open = false"
  >
    <template #modal-header>
      <div class="modal-header">
        Help us improve Spenda
      </div>
    </template>
    <template #default>
      <div class="form">
        <textarea
          placeholder="Enter your feedback here"
          v-model="feedback"
        />
      </div>
    </template>
    <template #modal-footer>
      <div class="modal-footer">
        <w3pp-button class="submit" @click="submit" background="#015fcc">Submit</w3pp-button>
      </div>
    </template>
  </w3pp-modal>

</template>
<script>
import { db, auth } from '@/firebase'
import w3ppModal from '@/components/w3ppModal'
import w3ppSlider from '@/components/w3ppSlider'
import w3ppClose from '@/components/w3ppClose'
import w3ppButton from '@/components/w3ppButton'
import chatIcon from '@iconify-icons/mdi/chat'
import plusIcon from '@iconify-icons/mdi/plus'
import { Icon, addIcon } from '@iconify/vue'
addIcon('plusIcon', plusIcon)
addIcon('chatIcon', chatIcon)
export default {
  name: 'NewEntry',
  components: {
    w3ppModal,
    w3ppSlider,
    w3ppClose,
    w3ppButton,
    Icon
  },
  data () {
    return {
      open: false,
      feedback: ''
    }
  },
  computed: {
  },
  methods: {
    submit () {
      if (!this.feedback) return
      this.open = false
      db.collection('feedback').add({
        date: new Date(),
        feedback: this.feedback,
        user: db.collection('users').doc(auth.currentUser.uid)
      }).then(() => {
        this.feedback = ''
        this.$snackBars.show('message', {
          message: 'Thank you. Your feedback has been received',
          action: {
              label: 'Got it!',
              fn: () => { this.$snackBars.delete('message') }
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-footer {
    padding: 16px;
    text-align: right;
    @media (min-width: 720px) {
      padding: 16px;
    }
  }
  .form {
    font-size: 18px;
    padding: 0 16px;
    @media (min-width: 720px) {
      padding: 0 36px;
    }
    textarea {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      min-height: 200px;
      background: #f6f8f9;
      padding: 8px;
      border: none;
      border-radius: 8px;
      font-size: 16px;
    }
  }

  .submit {
    background: #015fcc;
    height: 42px;
    padding: 0 16px;
    font-size: 18px;
    color: white;
    border: none;
    border-radius: 6px;
  }

  .modal-header {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 20px;
    padding-left: 16px;
    font-weight: 500;
    @media (min-width: 720px) {
      padding-left: 36px;
    }
  }

  .trigger {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      padding: 12px;
      .icon {
          color: #b8bfce;
          background: #3a3f48;
          margin-right: 8px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
      }
      .text {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        text-align: left;
        .helper {
          font-size: 12px;
        }
      }
  }
</style>
