<template>
    <div ref="container" class="viewport">
        <div class="container">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            isDown: false,
            startX: null,
            scrollLeft: null
        }
    },
    mounted () {
        this.addEventListeners()
    },
    beforeUnmount () {
        this.removeEventListeners()
    },
    methods: {
        // drag-scroll handlers
        dragStart (e) {
            const slider = this.$refs.container
            this.isDown = true
            slider.classList.add('active')
            this.startX = e.clientX - slider.offsetLeft
            this.scrollLeft = slider.scrollLeft
        },
        dragScroll (e) {
            if (!this.isDown) return
            const slider = this.$refs.container
            const x = e.clientX - slider.offsetLeft
            const walk = x - this.startX
            slider.scrollLeft = this.scrollLeft - walk
        },
        dragEnd () {
            this.isDown = false
            this.$refs.container.classList.remove('active')
        },
        addEventListeners () {
            this.$refs.container.addEventListener('mousedown', this.dragStart)
            this.$refs.container.addEventListener('mousemove', this.dragScroll)
            this.$refs.container.addEventListener('mouseleave', this.dragEnd)
            this.$refs.container.addEventListener('mouseup', this.dragEnd)
        },
        removeEventListeners () {
            this.$refs.container.removeEventListener('mousedown', this.dragStart)
            this.$refs.container.removeEventListener('mousemove', this.dragScroll)
            this.$refs.container.removeEventListener('mouseleave', this.dragEnd)
            this.$refs.container.removeEventListener('mouseup', this.dragEnd)
        }
    }
}
</script>

<style lang="scss" scoped>
    .viewport {
        overflow-x: scroll;
        white-space: nowrap;
        transition: all 0.2s;
        scrollbar-width: none; // Hide scrollbar for Firefox 64
        -ms-overflow-style: none; // Hide scrollbar for IE and Edge
        &::-webkit-scrollbar {
            display: none; // Hide scrollbar for Chrome, Safari and Opera
        }
        // stopping all highlighting on this component
        &, & * {
            -webkit-user-select: none;  /* Chrome all / Safari all */
            -moz-user-select: none;     /* Firefox all */
            -ms-user-select: none;      /* IE 10+ */
            user-select: none;          /* Likely future */
        }
        .container {
            text-align: left;
            white-space: nowrap;
            display: flex;
            align-items: center;
            & > div {
                margin-right: .5em;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
</style>
