<template>
    <w3pp-button
        v-if="loading || state.matches('starting')"
        class='icon'
        background="#ffffff"
        color="#2c3e50"
        light
    >
        <icon
            icon="loadingIcon"
            :inline="true"
            height="24"
            class="loading-icon"
        />
    </w3pp-button>
    <w3pp-button
        v-else-if="state.matches('no_user')"
        @click="signIn"
    >Sign in</w3pp-button>
    <w3pp-button
        v-else-if="state.matches('authenticated')"
        class='icon'
        @click="sideMenu = true"
        background="#ffffff"
        color="#2c3e50"
        light
    >
        <icon
            icon="accountIcon"
            height="24"
        />
    </w3pp-button>

    <w3pp-side-menu
        :open="sideMenu"
        @backgroundClick="sideMenu = false"
    >
        <template #modal-header>
            <div class="list-item display-name">
                <w3pp-button
                    class='block align-left'
                    background="transparent"
                    color="#b8bfce"
                >
                    <div class="icon"><icon
                        icon="accountIcon"
                        height="18"
                    /></div>
                    {{ auth.currentUser.displayName }}
                </w3pp-button>
            </div>
        </template>

        <div class="groups">
            <div class="group">
                <div class="list-item">
                    <w3pp-button
                        class='block align-left'
                        @click="signout"
                        background="transparent"
                        color="#b8bfce"
                    >
                        <div class="icon"><icon
                            icon="logoutIcon"
                            height="18"
                        /></div>
                        Sign out
                    </w3pp-button>
                </div>
            </div>
            <div class="group">
                <div class="list-item">
                    <submitFeedback/>
                </div>
            </div>
        </div>
    </w3pp-side-menu>
</template>

<script>
import firebase, { auth } from '@/firebase'
import w3ppButton from '@/components/w3ppButton'
import w3ppSideMenu from '@/components/w3ppSideMenu'
import submitFeedback from '@/components/submitFeedback'
import { authPromise } from '@/services/deferredPromises'
import accountIcon from '@iconify-icons/mdi/account-outline'
import logoutIcon from '@iconify-icons/mdi/logout'
import loadingIcon from '@iconify-icons/mdi/loading'
import { Icon, addIcon } from '@iconify/vue'
addIcon('loadingIcon', loadingIcon)
addIcon('accountIcon', accountIcon)
addIcon('logoutIcon', logoutIcon)
export default {
    components: {
        w3ppButton,
        w3ppSideMenu,
        submitFeedback,
        Icon
    },
    data () {
        const { send, state } = this.$user
        return {
            state,
            send,
            loading: false,
            sideMenu: false
        }
    },
    computed: {
        auth () {
            return auth
        }
    },
    mounted () {
        auth.onAuthStateChanged(data => {
            this.send({
                type: 'CHANGED',
                user: data
            })
            authPromise.resolve(data)
        })
    },
    methods: {
        signIn () {
            this.loading = false
            auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
                .catch(() => { /** avoiding empty function warning */ })
                .then(() => this.loading = false)
            
        },
        signout () {
            this.loading = true
            this.sideMenu = false
            auth.signOut()
                .catch(() => {})
                .then(() => this.loading = false)
        }
    }
}
</script>

<style lang="scss" scoped>
.button.icon {
    box-shadow: inset 0 0 1px 1px #e0e3e4;
    padding: 6px;
}
.list-item {
    font-size: 15px;

    &.display-name {
        font-size: 16px;
        .button {
            font-weight: 600;
        }
    }

    & + & {
        padding-top: 0;
    }

    .align-left {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        .icon {
            color: #b8bfce;
            background: #3a3f48;
            margin-right: 8px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
        }
    }
}

.groups {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .group {
        border-top: 1px solid #3a3f48;
    }
}

.button {
    border-radius: 4px;
    padding: 12px;
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 500;
}
</style>
<style lang="scss" scoped>
.loading-icon {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
</style>
