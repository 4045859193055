<template>
    <transition name="fade">
        <div v-if="open" class="modal-background"></div>
    </transition>
    <transition name="slide-fade">
        <div v-if="open" class="modal" :class="{ open }"  @click="backgroundOnClick">
            <div class="modal-frame" @click.stop="() => {}">
                <div class="modal-header"><slot name="modal-header"></slot></div>
                <div class="modal-body"><slot name="default"></slot></div>
                <div class="modal-footer"><slot name="modal-footer"></slot></div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    props: {
        open: Boolean
    },
    emits: ['backgroundClick', 'close'],
    methods: {
        backgroundOnClick () {
            this.$emit('backgroundClick')
        }
    }
}
</script>
<style lang="scss" scoped>
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.25);
    z-index: 2;
}

.modal {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    width: 100vw;
    height: 100vh;

    @media (min-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-frame {
        position: fixed;
        bottom: 0px;
        right: 0;
        width: 260px;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #31353D;
        box-shadow: rgba(0, 0, 0, .25) 0px 3px 16px 0px;
        // border-top-left-radius: 6px;
        // border-bottom-left-radius: 6px;

        .modal-header {
            flex-shrink: 0;
        }
        .modal-body {
            overflow: auto;
            flex: 1;
        }
        .modal-footer {
            flex-shrink: 0;
        }
    }
}
</style>

<style lang="scss" scoped>
    .slide-fade-enter-active,
    .slide-fade-leave-active {
        transition: all 0.2s ease-out;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(100%);
        opacity: 0;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
